
import TextAlternateGridItem from "./TextAlternateGridItem";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "TextAlternateGrid",

  components: { TextAlternateGridItem },

  mixins: [calculateFlexBasis],

  inject: {
    pageType: { default: "" },
  },

  props: { slice: { type: Object, required: true } },

  computed: {
    textAlignment() {
      return this.slice.label == "center" || this.slice.label === "velocity"
        ? "text-center"
        : "text-left";
    },
    isBundlePage() {
      return (
        this.pageType === "Static_page" &&
        this.$route.params.handle.includes("bundle")
      );
    },
  },
};
