
import { mapGetters } from "vuex";
import { createWidgetMixin } from "vue-instantsearch";
import { connectClearRefinements } from "instantsearch.js/es/connectors";

export default {
  mixins: [createWidgetMixin({ connector: connectClearRefinements })],

  computed: {
    ...mapGetters(["shownFacets"]),
    widgetParams() {
      return { includedAttributes: this.includedAttributes };
    },
    includedAttributes() {
      return this.shownFacets.map(f => f.name);
    },
    hasRefinements() {
      return (this.state && this.state.hasRefinements) || false;
    },
  },

  watch: {
    hasRefinements(value) {
      this.$nuxt.$emit("has-refinements", value);
    },
  },
  methods: {
    refine() {
      this.state.refine();
      this.$nuxt.$emit("clear-all");
    },
  },
};
