
export default {
  props: {
    product: { type: Object, required: true },
    productIndex: { type: Number, required: true },
    sliceTitle: { type: String, required: true },
  },
  computed: {
    selectedVariant() {
      return this.product.variants[0];
    },
    gtmData() {
      const variant = this.selectedVariant ?? {};

      return {
        ...variant,
        ...this.product,
        variant_title: variant.title,
        list: this.sliceTitle,
        position: this.productIndex + 1,
        from: "ShopifyProducts",
      };
    },
    excluded() {
      return this.product.source === "Optimizely" ? ["rating"] : [];
    },
  },
  watch: {
    product: {
      immediate: true,
      handler(value) {
        this.$store.dispatch("products/setProductsFetching", {
          productHandles: [value.handle],
          isFetching: false,
        });
      },
    },
  },
};
