import { render, staticRenderFns } from "./ProductTileImages.vue?vue&type=template&id=7853a8b4&scoped=true&"
import script from "./ProductTileImages.vue?vue&type=script&lang=js&"
export * from "./ProductTileImages.vue?vue&type=script&lang=js&"
import style0 from "./ProductTileImages.vue?vue&type=style&index=0&id=7853a8b4&prod&lang=postcss&scoped=true&"
import style1 from "./ProductTileImages.vue?vue&type=style&index=1&id=7853a8b4&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7853a8b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopifyImage: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/ShopifyImage.vue').default,SwiperNav: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/swiper-nav/SwiperNav.vue').default,AppBadge: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppBadge.vue').default,SvgIconDeliveryBigVan: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconDeliveryBigVan.vue').default,SvgIconAustraliaMade: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconAustraliaMade.vue').default,SvgIconInStock: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconInStock.vue').default,SvgIconCustomisable: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconCustomisable.vue').default,SvgIconSnoozeExclusive: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconSnoozeExclusive.vue').default})
