
import { mapGetters } from "vuex";

import PlpPriceRangeRefinement from "~/components/plp/PlpPriceRangeRefinement";
import PlpListRefinement from "~/components/plp/PlpListRefinement";
import algolia from "~/utils/algolia-utils";

export default {
  components: { PlpPriceRangeRefinement, PlpListRefinement },
  computed: mapGetters(["shownFacets"]),

  methods: {
    visibilityChanged(isVisible, entry) {
      this.$store.dispatch("app/setRefinementListVisiblity", isVisible);
    },
    getSortBy(facet) {
      const { facetSortFunctions } = algolia;
      return facetSortFunctions[facet.attribute]
        ? facetSortFunctions[facet.attribute]
        : ["count:desc", "name:asc"];
    },
    getComponent(facet) {
      return facet.name === "price"
        ? "PlpPriceRangeRefinement"
        : "PlpListRefinement";
    },
  },
};
