
import { gql } from "graphql-tag";
import SwiperClass, { Navigation, Manipulation } from "swiper";

import { tagableMixin } from "~/mixins";

const DIMENSION = "288";

const IMAGES_QUERY = gql`
  query images($ids: [ID!]!) {
    images: nodes(ids: $ids) {
      ... on MediaImage {
        image {
          id
          altText
          src: url
          w_288_h_288: url(
            transform: {
              maxWidth: 288
              maxHeight: 288
              preferredContentType: PNG
            }
          )
          w_288_h_288_webp: url(
            transform: {
              maxWidth: 288
              maxHeight: 288
              preferredContentType: WEBP
            }
          )
        }
      }
    }
  }
`;

export default {
  mixins: [tagableMixin],

  props: {
    mainImage: { type: Object, default: () => {} },
    priorityCampaign: { type: Object, default: () => {} },
    handle: { type: String, default: "" },
    tags: { type: [String, Array], default: "" },
    metafields: { type: Object, default: () => {} },
    selectedVariantId: { type: [Number, String], required: true },
    title: { type: [String, Array], default: "" },
    maxDiscount: { type: [Number, String], default: 0 },
  },

  data() {
    return { showNav: false, swiper: null, allImages: [this.mainImage] };
  },

  async fetch() {
    if (this.imageIds.length < 1) {
      return;
    }
    const { data } = await this.$getShopifyData({
      query: IMAGES_QUERY,
      variables: { ids: this.imageIds },
    });

    const images = (data.images ?? [])
      .filter(Boolean)
      .map(({ image }) => image);
    this.allImages = images.length === 0 ? [this.mainImage] : images;
  },

  fetchOnServer: false,

  computed: {
    swiperClass() {
      return `${this.handle}-product-title-images`;
    },
    swiperNextNavClass() {
      return `${this.handle}-swiper-next`;
    },
    swiperPrevNavClass() {
      return `${this.handle}-swiper-prev`;
    },
    swiperBegining() {
      return this.swiper?.isBegining;
    },
    swiperEnd() {
      return this.swiper?.isEnd;
    },
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: 1,
        loop: false,
        nested: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Manipulation],
        navigation: {
          nextEl: `.${this.swiperNextNavClass}`,
          prevEl: `.${this.swiperPrevNavClass}`,
        },
      };
    },
    isAustralianMade() {
      return this.metafields?.australian_made == 1;
    },
    quickship() {
      return this.metafields?.quickship;
    },
    saleTagBgColor() {
      return this.priorityCampaign?.saleColor;
    },
    saleTagText() {
      const saleTileTitle = this.priorityCampaign?.saleTileTitle ?? "";

      return this.maxDiscount
        ? saleTileTitle.replace(/__percentage__/g, this.maxDiscount)
        : saleTileTitle;
    },
    imageIds() {
      return this.metafields?.images ?? [];
    },
  },

  watch: {
    allImages(value) {
      if (value.length !== 0) {
        this.$nextTick(() => {
          this.swiper?.update?.();
        });
      }
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    onMouseEnter(e) {
      if (this.imageIds.length > 1) {
        this.showNav = true;
      }

      if (!this.swiper?.destroyed) {
        this.swiper?.slideNext?.();
      }
    },
    onMouseLeave() {
      this.showNav = false;
      if (!this.swiper?.destroyed) {
        this.swiper?.slidePrev?.();
      }
    },
    getOptions(image) {
      return {
        src: image?.w_288_h_288 ?? image?.src,
        webpSrc: image?.w_288_h_288_webp ?? image?.webpSrc,
        alt: this.title,
        widths: [DIMENSION],
        width: DIMENSION,
        height: DIMENSION,
      };
    },
  },
};
