
import { mapGetters, mapState } from "vuex";
import { reduce, minBy } from "ramda";

import {
  saleTagMixin,
  gtmEventMixin,
  metaColourMixin,
  tagableMixin,
  utagViewMixin,
} from "~/mixins";
import { slugify, camelize } from "~/utils";

const EVENT_LABEL = "product_image_click";
const TEALIUM_EVENT = "product_click";
const EVENT_ACTION_ECOMMERCE = "EECproductClick";

export default {
  mixins: [
    saleTagMixin,
    metaColourMixin,
    gtmEventMixin,
    tagableMixin,
    utagViewMixin,
  ],

  inject: {
    uid: { default: "" },
  },

  props: {
    product: { type: Object, required: true },
    selectedVariant: { type: Object, required: true },
    isRefined: { type: Boolean, default: false },
    gtmData: { type: Object, default: null },
  },

  computed: {
    ...mapState(["siteConfig"]),
    ...mapGetters(["shownFacets"]),
    widgetParams() {
      return { includedAttributes: this.shownFacets.map(f => f.name) };
    },
    metafields() {
      return {
        ...this.selectedVariant.metafields,
        ...this.product?.metafields,
      };
    },
    minPriceVariant() {
      return this.isRefined
        ? this.selectedVariant
        : reduce(
            minBy(v => Number(v.price)),
            { price: Infinity },
            this.product.variants
          );
    },
    metaColors() {
      const metaColorArgs = this.product.variants;
      return this.getMetaColors(metaColorArgs);
    },
    tags() {
      return this.product?.tags ?? [];
    },
    productImagesProps() {
      return {
        title: this.product.title,
        tags: this.tags,
        mainImage:
          this.selectedVariant.image ?? this.product.featuredImage ?? {},
        handle: this.product.handle,
        maxDiscount: this.maxDiscount,
        priorityCampaign: this.priorityCampaign,
        metafields: this.metafields,
        selectedVariantId: this.selectedVariant.id,
      };
    },
    productColorProps() {
      return {
        initSelectedColor: this.selectedOptions?.colour,
        metaColors: this.metaColors,
        selectedVariantId: this.selectedVariant.id,
        handle: this.product.handle,
        options: this.product.options,
      };
    },
    productPriceProps() {
      return {
        handle: this.product.handle,
        compareAtPrice: Number(this.minPriceVariant.compareAtPrice),
        price: Number(this.minPriceVariant.price),
        priorityCampaign: this.priorityCampaign,
        maxDiscount: this.maxDiscount,
        tags: this.tags,
      };
    },
    ratingProps() {
      return {
        handle: this.product.handle,
        reviewsAverage: this.metafields.reviews_average,
        reviewsCount: this.metafields.reviews_count,
        selectedVariantId: this.selectedVariant.id,
      };
    },
    title() {
      const feel = this.selectedOptions?.["comfort-feel"] ?? "";
      return this.oneProductMultipleFeels
        ? `${this.product.title} - ${feel}`
        : `${this.product.title}`;
    },
    selectedOptions() {
      const selectedOptions = this.selectedVariant?.selectedOptions ?? {};
      return Object.entries(selectedOptions).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [slugify(camelize(k, true, " "))]: v.value ?? v,
        }),
        {}
      );
    },
  },

  created() {
    // #region Campaign sale tag badges
    const saleTitle = this.metafields?.product_campaign_badge_title;
    const saleColor = this.metafields?.product_campaign_badge_color;

    this.getSaleTag({
      tags: this.tags,
      campaign: { saleTitle, saleColor },
      price: this.selectedVariant.price,
      compareAtPrice: this.selectedVariant.compareAtPrice,
    });
    // #endregion
  },

  methods: {
    handleClick() {
      if (this.product.source === "optimizely") {
        this.$optimizely.clickTracker(this.product.recId);
      }
      this.gtmProductClickEventFire();
    },
    gtmProductClickEventFire() {
      let from = this?.gtmData?.from;
      let list =
        from != "ShopifyProducts"
          ? this.generateListString()
          : this?.gtmData?.list;
      this.gtmProductClickEvent({ ...this.gtmData, list });

      const utagData = {
        event_action: EVENT_ACTION_ECOMMERCE,
        event_label: EVENT_LABEL,
        product_category: [this.product?.metafields?.product_category] ?? [],
        product_id: [this.product.id],
        product_name: [`${this.product.title}`],
        product_brand: this.product?.brandList?.split?.(",") ?? [],
        product_image_url: [this.allImages?.[0]?.src],
        product_price: [this.selectedVariant.price],
        product_sku: [this.selectedVariant.sku],
        product_variant: [`${this.selectedVariant?.variantTitle}`],
        tealium_event: TEALIUM_EVENT,
      };

      // gtm event
      if (this.gtmData.from !== "ShopifyProducts") {
        this.triggerGtmEvent();
      }

      this.utagEEcommerceClick(utagData);
    },

    triggerGtmEvent() {
      this.$gtm.gtagEvent("select_item", {
        item_list_id: this.uid,
        item_list_name: this.uid,
        items: [
          {
            item_id: this.selectedVariant?.sku,
            shopify_product_id: this.product?.id,
            item_name: this.product?.title,
            currency: "AUD",
            discount:
              this.selectedVariant?.compareAtPrice > this.selectedVariant?.price
                ? this.selectedVariant.compareAtPrice -
                  this.selectedVariant.price
                : 0,
            index:
              this.gtmData?.position >= 0 ? this.gtmData?.position + 1 : "",
            item_brand: this.product?.metafields?.brand_list ?? "",
            item_category: this.product?.metafields?.product_category ?? "",
            item_list_id: this.uid,
            item_list_name: this.uid,
            item_variant: this.selectedVariant?.selectedOptions?.size,
            price: this.selectedVariant?.price,
            quantity: 1,
          },
        ],
      });
    },
  },
};
