
import { createWidgetMixin } from "vue-instantsearch";
import { connectRefinementList } from "instantsearch.js/es/connectors";

export default {
  mixins: [createWidgetMixin({ connector: connectRefinementList })],

  props: {
    attribute: { type: String, required: true },
    sortBy: { type: [Function, Array], required: true },
    operator: { type: String, default: "or" },
    label: { type: String, default: "" },
    checkbox: { type: Boolean, default: true },
  },

  data: () => ({ open: false }),

  computed: {
    widgetParams() {
      return {
        attribute: this.attribute,
        sortBy: this.sortBy,
        operator: this.operator,
        ...(this.attribute === "options.size" && {
          transformItems: this.transformItems,
        }),
      };
    },
    hasRefinements() {
      if (this.state) {
        return this.state.items.some(item => item.isRefined);
      }
      return false;
    },
  },

  watch: {
    hasRefinements(value) {
      this.open = this.open ? this.open : value;
    },
  },

  created() {
    this.$nuxt.$on("clear-all", val => {
      this.open = false;
    });
  },

  methods: {
    refine(v) {
      this.state.refine(v);
    },
    activeStatus(val) {
      this.open = val;
    },
    transformItems(items) {
      let itemsToBeRemoved = ["Standard", "High", "Low"];
      return items.filter(item => !itemsToBeRemoved.includes(item.label));
    },
  },
};
