
export default {
  name: "ListOfProducts",

  props: { context: { type: Object, default: null } },

  computed: {
    handle() {
      return this.$route.params.handle;
    },
  },
};
