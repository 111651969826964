
import { mapGetters, mapState } from "vuex";
import ProductsFetch from "./ProductsFetch";

export default {
  components: { ProductsFetch },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: () => {} },
  },

  data: () => ({ products: [] }),

  async fetch() {
    const limits = this.slice.primary.max_products_limit;
    const tag = this.slice.primary.tag;
    const productIdQuery = this.context?.productId
      ? `&product=${this.context?.productId}`
      : "";
    const cartSkusQuery = this.context?.productSkus
      ? `&cart=${this.context?.productSkus}`
      : "";
    const url = `${this.$config.searchspringUrl}/recommend?tags=${tag}&limits=${limits}${productIdQuery}${cartSkusQuery}`;
    const productId = this.context?.productId;

    await this.$store.dispatch("products/fetchSearchspringProducts", {
      tag,
      url,
      productId,
    });

    this.products = this.normalizeProducts(
      this.getSearchspringProducts(tag, productId)
    );
  },

  fetchOnServer: false,

  computed: {
    ...mapGetters("bag", ["lines"]),
    ...mapGetters("products", ["getSearchspringProducts"]),
    ...mapState("products", ["searchspringProducts"]),
  },

  methods: {
    normalizeProducts(items) {
      return items.map(({ mappings, attributes }) => {
        const options = JSON.parse(attributes.options);

        return {
          handle: attributes?.handle,
          recId: mappings?.core.uid,
          title: mappings.core.name,
          id: mappings?.core.id,
          brandList: mappings?.core.brand,
          collections: [],
          images: attributes?.images?.split("|"),
          featuredImage: {
            id: mappings.core.thumbnailImageUrl,
            src: mappings.core.thumbnailImageUrl,
            w_288_h_288: mappings.core.thumbnailImageUrl,
          },
          options,
          variants: JSON.parse(attributes.variants).map(variant => {
            const metafields = variant?.metafields.reduce(
              (result, { key, value }) => {
                result[key] = key === "images" ? JSON.parse(value) : value;
                return result;
              },
              {}
            );

            return {
              price: variant.price,
              compareAtPrice: variant.compare_at_price,
              id: variant.id,
              productId: variant.product_id,
              title: variant.title,
              metafields,
              selectedOptions: options.reduce((result, { name, values }) => {
                result[name] = {
                  name,
                  value: values.length > 0 ? values[0] : null,
                };
                return result;
              }, {}),
            };
          }),
          metafields: attributes.metafields
            ? JSON.parse(attributes.metafields).map(({ key, value }) => ({
                [key]: value,
              }))
            : [],
        };
      });
    },
  },
};
