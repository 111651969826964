
import CarouselImage from "./CarouselImage";
import { utagViewMixin } from "~/mixins";

const EVENT_LABEL = "Instagram Mobile";

export default {
  components: { CarouselImage },

  mixins: [utagViewMixin],

  props: {
    gallery: { type: Array, default: () => {} },
    index: { type: Number, default: 0 },
    primary: { type: Object, default: () => {} },
    getImage: { type: Function, default: () => {} },
  },

  data() {
    return { dataIndex: this.index, text: {}, eventLabel: EVENT_LABEL };
  },

  computed: {
    slideVisible() {
      return this.gallery[this.dataIndex];
    },
  },

  methods: {
    clickSwiper(index) {
      if (this.$refs["carousel-image"]) {
        this.$refs["carousel-image"].imageIndex = 0;
      }

      if (this.dataIndex == 0 && index == -1) {
        this.dataIndex = this.gallery.length - 1;
      } else {
        this.dataIndex = (this.dataIndex + index) % this.gallery.length;
      }
    },
  },
};
