
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { validation, utagViewMixin } from "~/mixins";

const EVENT_LABEL = "members";
const EVENT_ACTION = "subscribe";
const EVENT_CATEGORY = "member_signup";

export default {
  components: { vSelect },

  mixins: [validation, utagViewMixin],

  data: () => ({
    success: false,
    isSubmitting: false,
    first_name: null,
    last_name: null,
    email: null,
    postcode: null,
    mobile: null,
    store: null,
    errors: {
      first_name: [],
      last_name: [],
      email: [],
      postcode: [],
      mobile: [],
      store: [],
    },
    formErrors: [],
    stores: [],
  }),

  async fetch() {
    const { data } = await this.$axios.get(
      `${this.$config.snoozeEcom}/all-stores`
    );

    this.stores = data
      .filter(item => item.accepting_orders)
      .map(item => ({ label: item.name, id: item.pronto_id }))
      .sort((firstItem, secondItem) =>
        firstItem.label > secondItem.label ? 1 : -1
      );
  },

  methods: {
    validateFirstName() {
      this.errors.first_name = [];
      if (!this.first_name) {
        this.errors.first_name.push("Please fill this field");
      }

      if (/[^ A-Za-z0-9_]/.test(this.first_name)) {
        this.errors.first_name.push("Please enter valid first name");
      }
    },
    validateLastName() {
      this.errors.last_name = [];
      if (!this.last_name) {
        this.errors.last_name.push("Please fill this field");
      }

      if (/[^ A-Za-z0-9_]/.test(this.last_name)) {
        this.errors.last_name.push("Please enter valid last name");
      }
    },
    validateMyEmail() {
      this.errors.email = [];
      if (!this.email) {
        this.errors.email.push("Please fill this field");
      }

      if (!this.validateEmail(this.email)) {
        this.errors.email.push("Please include @ and . in email address");
      }
    },
    validatePostcode() {
      this.errors.postcode = [];
      if (!this.postcode) {
        this.errors.postcode.push("Please fill this field");
      }

      if (!/^\d{4}$/.test(this.postcode)) {
        this.errors.postcode.push("Please enter valid postcode");
      }
    },
    validateMobile() {
      this.errors.mobile = [];
      if (!this.mobile) {
        this.errors.mobile.push("Please fill this field");
      }

      if (!/^04/.test(this.mobile)) {
        this.errors.mobile.push("Mobile number must start with 04");
      }

      if (!/^\d{10}$/.test(this.mobile)) {
        this.errors.mobile.push("Please enter valid mobile number");
      }
    },
    validateStore() {
      this.errors.store = [];
      if (!this.store) {
        this.errors.store.push("Please select a Store Name");
      }
    },
    validate() {
      this.validateFirstName();
      this.validateLastName();
      this.validateMyEmail();
      this.validatePostcode();
      this.validateMobile();
      this.validateStore();
    },
    async createMember() {
      const url = `${this.$config.snoozeCrm}/member`;
      const body = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        mobile: this.mobile,
        postcode: this.postcode,
        store_name: this.store.label,
        store_id: this.store.id,
        source: "online",
      };
      this.isSubmitting = true;

      try {
        const { data } = await this.$axios.post(url, body, {
          headers: {
            apikey: this.$config.snoozeSystemApiKey,
            Accept: "application/json",
          },
        });
        this.success = true;
        this.utagClick({
          event_label: EVENT_LABEL,
          event_category: EVENT_CATEGORY,
          event_action: EVENT_ACTION,
          tealium_event: EVENT_CATEGORY,
          customer_id: data?.customer_id,
          customer_email: data?.email,
          preferred_store: data?.store_name,
        });
      } catch (err) {
        for (const { field, message } of err?.response?.data?.errors ?? []) {
          this.errors?.[field]?.push?.(message);
        }

        const errorMessage =
          err?.response?.data?.errors?.[0]?.message ?? err?.message;
        if (errorMessage) {
          this.formErrors.push(errorMessage);
          this.$bugsnag.notify(err, e => e.addMetadata("body", body));
        }
      }
      this.isSubmitting = false;
    },

    async handleSubmit(e) {
      this.validate();
      if (this.hasErros()) {
        return false;
      }

      this.formErrors = [];
      await this.createMember();
      this.$gtm.gtagEvent("sign_up", { email: this.email });
    },
  },
};
