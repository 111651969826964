
import SwiperClass, { Navigation } from "swiper";

import ShopifyProductTile from "~/components/common/AppShopifyProductTile";

export default {
  components: { ShopifyProductTile },

  props: {
    products: { type: Array, required: true },
    sliceTitle: { type: String, required: true },
    swiperClass: { type: String, default: "" },
  },

  data: () => ({ swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 2,
        breakpoints: {
          // when window width is >= 767
          767: {
            slidesPerGroup: 4,
          },
        },
        slidesPerView: "auto",
        loopFillGroupWithBlank: false,
        modules: [Navigation],
        navigation: {
          nextEl: `.${this.swiperClass}-next`,
          prevEl: `.${this.swiperClass}-prev`,
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
