
import { mapGetters } from "vuex";

import tagableMixin from "~/mixins/tagableMixin";

export default {
  mixins: [tagableMixin],

  props: {
    handle: { type: String, default: "" },
    price: { type: Number, required: true },
    compareAtPrice: { type: Number, default: -1 },
    tags: { type: Array, default: () => [] },
    priorityCampaign: { type: Object, default: () => {} },
    maxDiscount: { type: [Number, String], default: 0 },
  },

  computed: {
    ...mapGetters("products", ["getProductFetchingStatus"]),
    showCompareAtPrice() {
      return this.canShowCompareAtPrice(
        this.priorityCampaign,
        this.compareAtPrice,
        this.price
      );
    },
    saleTagBgColor() {
      return this.priorityCampaign?.saleColor ?? "";
    },
    saleTagText() {
      return this.priorityCampaign?.saleTileTitle;
    },
    saleTagTextMobile() {
      return this.priorityCampaign?.saleTileTitleMobile;
    },
    campaignPrice() {
      return this.price - (this.price * this.maxDiscount) / 100;
    },
    enhancedUIRrpPrice() {
      return this.showCompareAtPrice && this.maxDiscount == 0
        ? this.campaignPrice
        : this.price;
    },
    showEnhancedUI() {
      return this.priorityCampaign?.enhancedUI;
    },
    showRRPText() {
      return this.priorityCampaign?.showRRPText ?? true;
    },
  },
};
