import { render, staticRenderFns } from "./PlpProductList.vue?vue&type=template&id=68844cc9&"
import script from "./PlpProductList.vue?vue&type=script&lang=js&"
export * from "./PlpProductList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductTagConfigure: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/ProductTagConfigure.vue').default,ProductMain: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/ProductMain.vue').default,AlgoliaLoadingIndicator: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/AlgoliaLoadingIndicator.vue').default})
