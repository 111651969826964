
import SwiperClass, { Navigation } from "swiper";

import CarouselImage from "./CarouselImage";
import { utagViewMixin } from "~/mixins";

const EVENT_LABEL = "Instagram Mobile";

export default {
  components: { CarouselImage },

  mixins: [utagViewMixin],

  props: {
    gallery: { type: Array, default: () => {} },
    index: { type: Number, default: 0 },
    primary: { type: Object, default: () => {} },
    getImage: { type: Function, default: () => {} },
  },

  data() {
    return {
      dataIndex: this.index,
      text: {},
      eventLabel: EVENT_LABEL,
    };
  },

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        initialSlide: this.index,
        loop: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation],
        navigation: {
          nextEl: ".instagram-m-modal-swiper-next",
          prevEl: ".instagram-m-modal-swiper-prev",
        },
      };
    },
    slideVisible() {
      return this.gallery[this.dataIndex];
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.mobile-instagram",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
