
import { AisInstantSearchSsr, AisConfigure } from "vue-instantsearch";
import createSearchSSRMixin from "~/mixins/createSearchSSRMixin";
import facetsMixin from "~/mixins/facetsMixin";

export default {
  components: { AisInstantSearchSsr, AisConfigure },

  mixins: [facetsMixin],

  props: {
    collectionRuleSet: { type: Object, required: true },
    bannerConfig: { type: Object, required: true },
  },

  data() {
    const {
      algoliaAppId,
      algoliaKey,
      algoliaProductsIndex: indexName,
      algoliaDefaultSortProductsIndex,
    } = this.$store.state.siteConfig;

    const mixin = createSearchSSRMixin({
      algoliaAppId,
      algoliaKey,
      indexName,
      router: this.$router,
      facets: ["refinementList", "sortBy", "range", "page"],
      algoliaDefaultSortProductsIndex,
      bugsnag: this.$bugsnag,
    });

    return { ...mixin.data(), viewAll: this.$route.query.view_all == 1 };
  },

  computed: {
    hitsPerPage() {
      return this.viewAll
        ? 1000
        : this.$device.isMobile
        ? this.bannerConfig?.display_banners
          ? 17
          : 18
        : this.bannerConfig?.display_banners
        ? 11
        : 12;
    },
  },
};
