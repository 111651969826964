
export default {
  props: {
    item: { type: Object, default: () => {} },
    label: { type: String, default: () => "" },
  },

  data: () => ({ selectedOption: null }),

  computed: {
    imgProps() {
      const img = this.item?.optional_icon;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
    optionsSelector() {
      const textValue = this.item.options_selector?.[0]?.text;
      return textValue ? JSON.parse(textValue) : null;
    },
    buttonLink() {
      const queryParams = this.selectedOption
        ? `?variant=${this.selectedOption.variantId}`
        : "";

      return this.$prismic.asLink(this.item.optional_button_link) + queryParams;
    },
  },

  created() {
    this.selectedOption = this.optionsSelector?.[0];
  },
};
